import InputPhone from './InputPhone';

export default function PhoneView({ value, style }: { value: string | undefined; style?: React.CSSProperties }) {
  return (
    <InputPhone
      inputProps={{
        required: true
      }}
      disabled
      inputStyle={{
        border: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        width: '100%',
        fontWeight: 400,
        fontSize: 15,
        paddingLeft: 35,
        cursor: 'default',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        ...style
      }}
      searchStyle={{
        border: 0,
        paddingLeft: 0
      }}
      containerStyle={{
        border: 0,
        paddingLeft: 0,
        marginLeft: -4
      }}
      buttonStyle={{
        border: 0,
        borderColor: 'transparent',
        borderRadius: 0,
        backgroundColor: 'transparent',
        paddingLeft: 0
      }}
      disableSearchIcon
      disableDropdown
      value={value}
    />
  );
}
