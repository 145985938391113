import React, { useEffect } from 'react';
// MaterialUI
import {
  Collapse,
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { Link, useNavigate, useLocation } from 'react-router-dom';
//icons
import { default as Logo } from '../../assets/dashboard/sidebar/logo.svg';
import {
  TransportMethodIcon,
  ProjectsIcon,
  CompanyManagementIcon,
  UserManagementIcon,
  AddressesIcon,
  DriversIcon,
  VehicleIcon,
  CompletedTransportersIcon,
  ReportsIcon
} from '@/assets/icons/index';
import { default as PinLogo } from '../../assets/dashboard/sidebar/pin_logo_icon.svg';
import Navbar from './navbar';
import { useTranslation } from 'react-i18next';

const IconMapObject = (menu: string): React.ReactNode => {
  switch (menu) {
    case 'Vehicles':
      return <img src={VehicleIcon} width={20} alt="Bill Icon" />;
    case 'Reports':
      return <img src={ReportsIcon} width={20} alt="Reports Icon" />;
    case 'Drivers':
      return <img src={DriversIcon} width={20} alt="Inventory Icon" />;
    case 'UserManagement':
      return <img src={UserManagementIcon} width={20} alt="UserManagement Icon" />;
    case 'CompanyManagement':
      return <img src={CompanyManagementIcon} width={20} alt="CompanyManagement Icon" />;
    case 'TransportManagement':
      return <img src={TransportMethodIcon} width={20} alt="TransportManagement Icon" />;
    case 'CompleteTransportManagement':
      return <img src={CompletedTransportersIcon} width={20} alt="CompletedTransporters Icon" />;
    case 'Projects':
      return <img src={ProjectsIcon} width={20} alt="CompletedTransporters Icon" />;
    case 'Addresses':
      return <img src={AddressesIcon} width={20} alt="CompletedTransporters Icon" />;
  }
};
const drawerWidth = 244;

//Drawer Styles and functions----------------------------------------
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: 60
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'fixed',
  zIndex: 1200,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function Sidebar(): JSX.Element {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState('');
  const navigate = useNavigate();
  function handleOpenCollapse(props: string): void {
    setOpenCollapse(props);
  }

  const { t } = useTranslation();

  const sidebar = [
    {
      url: '/',
      text: t('shipmentManagement'),
      logo: 'TransportManagement'
    },

    {
      url: '/company-info',
      text: t('companyInformation'),
      logo: 'CompanyManagement'
    },
    {
      url: '/company/project',
      text: t('projects'),
      logo: 'Projects'
    },
    {
      url: '/usermanagement',
      text: t('users'),
      logo: 'UserManagement'
    },
    {
      url: '/inventory/driver',
      text: t('drivers'),
      logo: 'Drivers'
    },
    {
      url: '/inventory/vehicle',
      text: t('vehicles'),
      logo: 'Vehicles'
    },
    {
      url: '/company/address',
      text: t('addresses'),
      logo: 'Addresses'
    },
    {
      url: '/completed-transportations',
      text: t('pastTransfers'),
      logo: 'CompleteTransportManagement'
    }, 
    {
      url: '/reports',
      text: t('reports'),
      logo: 'Reports'
    }
  ];

  const location = useLocation();

  const [activeMenu, setMenuActive] = React.useState<string>('');

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setMenuActive(t('shipmentManagement')); // veya menü text'i tam olarak neyse
      return;
    }

    sidebar.forEach((menu) => {
      if (menu?.url === path) {
        setMenuActive(menu?.text);
      } else if (menu?.submenu != null) {
        menu?.submenu.forEach((submenu) => {
          if (submenu.url === path) {
            setMenuActive(submenu.text);
          }
        });
      }
    });
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        marginRight: 25
      }}
    >
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: theme.palette.common.white,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Box-shadow'u düşürmek için
          height: '60px', // Yüksekliği azaltmak için,
          zIndex: 1
        }}
      >
        <Toolbar sx={{ minHeight: '48px' }}>
          {' '}
          {/* Toolbar'ın yüksekliğini de ayarlayın */}
          {/* Navbar component is here!! */}
          <Navbar />
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
          setOpenCollapse('');
        }}
        variant="permanent"
        PaperProps={{
          sx: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.white
          }
        }}
      >
        <DrawerHeader
          sx={{
            justifyContent: open ? 'flex-start' : 'center',
            position: 'sticky',
            top: 0,
            background: theme.palette.common.white,
            pt: 0,
            pb: 2,
            pl: open ? 0 : 1,
            zIndex: 10
          }}
        >
          <Link to="/">
            <Box
              src={open ? Logo : PinLogo}
              alt="TIRPORT"
              component="img"
              sx={{
                width: open ? 130 : '85%',
                height: 41.2,
                display: 'block',
                paddingLeft: open ? 7.5 : 0
              }}
            />
          </Link>
        </DrawerHeader>
        <List>
          {sidebar.map((menu, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{
                    display: 'block',
                    textAlign: 'center'
                  }}
                >
                  <ListItemButton
                    sx={{
                      px: 3.9,
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      borderRadius: '8px',
                      marginLeft: 2,
                      marginRight: 2,
                      backgroundColor:
                        activeMenu === menu.text ||
                          (menu?.submenu != null && menu?.submenu.some((submenu) => submenu.text === activeMenu))
                          ? '#FFC400 !important'
                          : 'transparent !imprtant'
                    }}
                    disabled={menu.text.charAt(menu.text.length - 1) === '#'}
                    onClick={() => {
                      menu?.submenu != null && handleOpenCollapse(menu.text);
                      menu?.text === openCollapse && handleOpenCollapse('');
                      menu?.submenu == null &&
                        navigate(menu.text.charAt(menu.text.length - 1) === '#' ? '#' : menu.url, {
                          replace: false
                        });
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      {IconMapObject(menu.logo)}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.text}
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme.palette.primary.main,
                          fontSize: '14px'
                        },
                        opacity: open ? 1 : 0
                      }}
                    />
                    {menu?.submenu != null &&
                      open &&
                      (openCollapse === menu.text ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </ListItem>
                {menu?.submenu != null && open && (
                  <Collapse in={openCollapse === menu.text} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {menu?.submenu.map((submenu, i) => (
                        <ListItemButton
                          disabled={submenu.text.charAt(submenu.text.length - 1) === '#'}
                          key={i}
                          sx={{ pl: 13 }}
                          onClick={() =>
                            navigate(submenu.text.charAt(submenu.text.length - 1) === '#' ? '#' : submenu.url, {
                              replace: false
                            })
                          }
                        >
                          <ListItemText
                            sx={{
                              '& .MuiTypography-root': {
                                color: 'white',
                                fontSize: '13px'
                              },
                              opacity: open ? 1 : 0
                            }}
                            primary={submenu.text}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
}
