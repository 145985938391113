import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Index } from './types';

const initialState = {
    options: [],
    projectData: {
        selectedProjects: [],
        selecteAll: false
    }

} as Index;

export const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    reset: () => initialState,
    setOptions: (state, action: PayloadAction<Index['options']>) => {
      state.options = action.payload;
    },
    setProjectData: (state, action: PayloadAction<Index['projectData']>) => {
      state.projectData = action.payload;
    }
  }
});

export const { reset, setOptions, setProjectData } = reportSlice.actions;

export default reportSlice.reducer;
