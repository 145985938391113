import { getCookie } from 'react-use-cookie';
import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';
import i18next from 'i18next';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { notifyError } from '@/components/notification/notify';
import { LOGGED_OUT } from '@/page/Login/reducer/auth-slice';

export default function baseQuery(URL: string) {
  const baseQuery = fetchBaseQuery({
    baseUrl: URL,
    prepareHeaders: (headers) => {
      const user = getCookie('user') ? JSON.parse(getCookie('user')) : null;
      if (!user || !user?.token?.accessToken) {
        return headers;
      }

      if (user && user.token.accessToken) {
        headers.set('Authorization', `Bearer ${user.token.accessToken.jwt}`);
        headers.set('Accept-Language', i18next.language || 'en');
      }
      return headers;
    }
  });

  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
    return await baseQuery(args, api, extraOptions);
  };
}

export const rtkQueryErrorMiddleware: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action;

    if ((payload as any).status === 401) {
      const errorMessage = (payload as any)?.data?.message || (payload as any)?.error || i18next.t('unknowAnStatusCreated');
      notifyError({ message: errorMessage, title: i18next.t('error') });
      api.dispatch(LOGGED_OUT());
    }
  }

  return next(action);
};
