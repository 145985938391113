import React from 'react';
import { toast, ToastContainer, ToastContainerProps, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/scss/app/components/common/notify.css';

import { default as NotifySuccessIcon } from '../../assets/notification/icons/successNotificationIcon.svg';
import { default as NotifyErrorIcon } from '../../assets/notification/icons/errorNotificationIcon.svg';
import { default as NotifyWarningIcon } from '../../assets/notification/icons/warningNotificationIcon.svg';

export enum NotifyType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

const NotifyIconUrls: Record<NotifyType, string> = {
  [NotifyType.Info]: NotifySuccessIcon,
  [NotifyType.Success]: NotifySuccessIcon,
  [NotifyType.Error]: NotifyErrorIcon,
  [NotifyType.Warning]: NotifyWarningIcon
};

interface INotify {
  containerId?: string;
  title?: React.ReactNode | string;
  message: React.ReactNode | string;
  position?: ToastPosition;
}

interface INotifyWithType extends INotify {
  notifyType: NotifyType;
}

const DefaultContainerId: string = '__container1';

interface NotifyContainerProps extends ToastContainerProps {}

export const NotifyContainer: React.FC<NotifyContainerProps> = ({ containerId, ...props }) => {
  const actualContainerId = containerId ?? DefaultContainerId;

  return (
    <ToastContainer
      containerId={actualContainerId}
      closeOnClick
      style={{ maxWidth: '600px', width: 'fit-content' }}
      autoClose={7000}
      {...props}
    />
  );
};

const NotifyContent: React.FC<{
  title?: React.ReactNode | string;
  message: React.ReactNode | string;
  iconUrl: string;
  position?: string;
}> = ({ title, message, iconUrl }) => {
  return (
    <div
      className="Notify__notify-body"
      style={{
        display: 'flex',
        gap: '12px'
      }}
    >
      <img className="Notify__notify-icon" src={iconUrl} alt="notify" />
      <div className="Notify__notify-text-container">
        <div style={{ flex: 1 }}>
          {title && (
            <div
              style={{ fontWeight: 'bold', marginBottom: '8px', marginTop: '8px' }}
              className="Notify__notify-title"
            >
              {title}
            </div>
          )}
        </div>
        <div
          className="Notify__notify-message"
          style={{
            opacity: '0.5',
            fontFamily: 'sans-serif',
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {message}
        </div>
      </div>
    </div>
  );
};

export const notify = ({
  containerId = DefaultContainerId,
  title,
  message,
  notifyType,
  position
}: INotifyWithType): number | string => {
  return toast(<NotifyContent title={title} message={message} iconUrl={NotifyIconUrls[notifyType]} />, {
    containerId,
    position: position? position : 'top-center',
    type: notifyType,
    style: {
      maxWidth: '600px',
      width: 'fit-content',
      minWidth: '300px'
    },
    autoClose: 7000
  });
};

export const notifySuccess = ({ containerId = DefaultContainerId, title, message,position }: INotify): number | string => {
  return notify({
    containerId,
    title,
    message,
    notifyType: NotifyType.Success,
    position: position
  });
};

export const notifyError = ({ containerId = DefaultContainerId, title, message,position }: INotify): number | string => {
  return notify({ containerId, title, message, notifyType: NotifyType.Error, position: position });
};

export const notifyWarning = ({ containerId = DefaultContainerId, title, message,position }: INotify): number | string => {
  return notify({
    containerId,
    title,
    message,
    notifyType: NotifyType.Warning,
    position: position
  });
};
